import React from 'react';
import SingleVitalChart from './SingleVitalChart';

function VitalsChartsGrid({ vitalsData = []}) {
  const groupedVitals = {};

  vitalsData.forEach((timeSlice, timeIndex) => {

    timeSlice.forEach((vitalObj) => {
      const { key, title, value, unit } = vitalObj;
      if (!groupedVitals[key]) {
        groupedVitals[key] = {
          key,
          title,
          unit,
          data: [],
        };
      }
      groupedVitals[key].data.push({
        time: timeIndex,
        value: Number(value) || 0,
      });
    });
  });

  const chartDataArray = Object.values(groupedVitals);
  
  return (
    <div className="chartGrid">
      {chartDataArray.map((vital) => {
        return (
          <div
            key={vital.key}
            className="chartItem"
          >
            <SingleVitalChart vital={vital} />
          </div>
        );
      })}
    </div>
  );
}

export default VitalsChartsGrid;