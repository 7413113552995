import React, { useEffect, useState } from 'react';
// import logo from '../path-to-your-logo.png'; // Update this path to your logo's location
import './stylesheets/Header.css';
import { useLocation } from 'react-router-dom';
import { useLogout } from './LogoutContext';


const Header = () => {
  const [showLogoutButton, setShowLogoutButton] = useState(false)
  const location = useLocation();
  const { handleLogoutClick } = useLogout();

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setShowLogoutButton(true)
    } else {
      setShowLogoutButton(false)
    }
  }, [location])

  return (
    <div className="header">
      <img src={"https://www.carex.ai/img/Group%20104.png"} width={350} alt="Carex Logo" className="header-logo" />
      <p className='version'>Build: v2.6.1</p>
      {showLogoutButton && <button text="Logout" className='logoutButton' onClick={handleLogoutClick}>Logout</button>}
    </div>
  );
};

export default Header;
