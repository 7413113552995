import React from 'react';
import {
  LineChart,
  Line,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  XAxis,
} from 'recharts';
import { Heart, Activity, TrendingUp, Zap, ChevronDown } from 'lucide-react';

const vitalConfig = {
  heartRate: {
    title: 'Heart Rate',
    unit: 'bpm',
    color: '#FF6B6B',
    bgColor: '#FFF5F5',
    domain: [40, 120],
  },
  systolic: {
    title: 'Systolic BP',
    unit: 'mmHg',
    color: '#4ECDC4',
    bgColor: '#F0FFFD',
    domain: [80, 180],
  },
  diastolic: {
    title: 'Diastolic BP',
    unit: 'mmHg',
    color: '#45B7D1',
    bgColor: '#F0FAFF',
    domain: [40, 120],
  },
  heartRateVariability: {
    title: 'HRV',
    unit: 'ms',
    color: '#96CEB4',
    bgColor: '#F3FBF7',
    domain: [0, 100],
  },
  stressIndex: {
    title: 'Stress Index',
    unit: '%',
    color: '#D4A5A5',
    bgColor: '#FFF7F7',
    domain: [0, 100],
  },
};

function SingleVitalChart({ vital }) {
  const config = vitalConfig[vital.key] || {
    title: vital.title,
    unit: vital.unit,
    color: '#805AD5',
    bgColor: '#F0F0F0',
    domain: ['auto', 'auto'],
  };

  console.log('config is', config);
  const Icon = () => {
    switch (config?.title) {
      case 'Heart Rate':
        return <Heart className="measurementIcon" size={14} style={{ color: config?.color, alignSelf: 'center' }} />;
      case 'Stress Index':
        return <Zap className="measurementIcon" size={14} style={{ color: config?.color, alignSelf: 'center' }} />;
      case 'HRV':
        return <TrendingUp className="measurementIcon" size={14} style={{ color: config?.color, alignSelf: 'center' }} />;
      case 'Systolic BP':
        return <Activity className="measurementIcon" size={14} style={{ color: config?.color, alignSelf: 'center' }} />;
      case 'Diastolic BP':
        return <Activity className="measurementIcon" size={14} style={{ color: config?.color, alignSelf: 'center' }} />;
      default:
        return <TrendingUp className="measurementIcon" size={14} style={{ color: config?.color, alignSelf: 'center' }} />;
    }
  };

  const data = vital.data || [];

  const values = data.map(d => d.value);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const mid = (min + max) / 2;

  return (
    <div
      style={{
        backgroundColor: config.bgColor,
        borderRadius: '10px',
        padding: '1rem',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        width: '100%',
        transition: 'opacity 0.2s ease-in-out',
        height: '80%'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>

          <h3 style={{ margin: '0 0 0.5rem', color: config.color }}>
            {config.title}
          </h3>
          <span style={{ color: config.color, fontSize: '14px' }}>{config.unit}</span>
        </div>

        <div style={{ display: 'flex' }}>
          <h3 style={{ margin: '0 0 0.5rem', color: config.color, fontSize: '32px' }}>
            {/* Print the current value */}
            {data.length > 0 ? data[data.length - 1].value : 0}
          </h3>
          <Icon />
        </div>
      </div>
      {/* <div style={{ fontSize: '0.9rem', color: '#6B7280', marginBottom: '0.5rem' }}>
        Data points: {data.length}
      </div> */}

      <ResponsiveContainer width="100%" height={150}>
        <LineChart data={data}>
          <YAxis
            domain={config.domain}
            orientation="right"
            axisLine={false}
            tickLine={false}
            width={30}
            tick={{ fill: '#9CA3AF', fontSize: 10 }}
          />

          {/* Reference lines at min, mid, max */}
          <ReferenceLine
            y={max}
            stroke={config.color}
            strokeDasharray="3 3"
            strokeOpacity={0.3}
          />
          <ReferenceLine
            y={mid}
            stroke={config.color}
            strokeDasharray="3 3"
            strokeOpacity={0.3}
          />
          <ReferenceLine
            y={min}
            stroke={config.color}
            strokeDasharray="3 3"
            strokeOpacity={0.3}
          />

          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const point = payload[0].payload;
                return (
                  <div
                    style={{
                      backgroundColor: '#2D3748',
                      border: `1px solid ${config.color}`,
                      color: '#F0EEEE',
                      padding: '0.5rem',
                      borderRadius: '4px',
                    }}
                  >
                    <strong>
                      {point.value} {config.unit}
                    </strong>
                    <div style={{ fontSize: '0.8rem', color: '#9CA3AF' }}>
                      time: {point.time}
                    </div>
                  </div>
                );
              }
              return null;
            }}
            cursor={{ stroke: config.color, strokeWidth: 1, strokeDasharray: '3 3' }}
          />

          <Line
            type="monotone"
            dataKey="value"
            stroke={config.color}
            strokeWidth={2}
            dot={{ fill: config.color, r: 1 }}
            activeDot={{ r: 7 }}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SingleVitalChart;
