import React from 'react';
import './stylesheets/ActionButton.css';
import { Video } from 'lucide-react';

const ActionButton = ({ iconPath, text, onClick, color = "#556ee6", fontSize = '20px', width = "100%", padding = "20px 20px", disabled = false, hidden = false }) => {
    const buttonStyle = {
        fontSize: fontSize,
        backgroundColor: color,
        width: width,
        padding: padding
    };

    let Icon;
    switch (iconPath) {
        case 'Video':
            Icon = <Video size={24} style={{ color: 'white', marginRight: '8px' }} />;
            break;
        default:
            Icon = null;
    }

    return (
        <button className="action-button" style={buttonStyle} onClick={onClick} disabled={disabled} hidden={hidden}>
            {/* Display the icon if it is not null */}
            {Icon}
            {text}
        </button>
    );
};

export default ActionButton;
