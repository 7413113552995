import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import zoomUtils from '../utils/zoom';
import './stylesheets/MeetingPage.css';
import VitalsColumn from '../components/VitalsColumn';
import MeasurementProgress from '../components/MeasurementProgress';
import { getDynamicLink, getScreenItems, isMeasurementInterrupted } from '../utils/helpers';
import ActionButton from "../components/ActionButton"
import VitalsChartsGrid from '../components/VitalsChartsGrid';

const MeetingPage = () => {
  const [patientId, setPatientId] = useState('');
  const [vitalsData, setVitalsData] = useState([]);
  // const [hoveredKey, setHoveredKey] = useState(null);
  const [progress, setProgress] = useState(0.0);
  const [measurementState, setMeasurementState] = useState('');
  const [measurementMode, setmeasurementMode] = useState('');
  const [measurementInfo, setMeasurementInfo] = useState('');
  const [interruptionMessage, setInterruptionMessage] = useState('');
  const [meetingDetails, setMeetingDetails] = useState({ meetingNumber: '', meetingPassword: '' });
  // const [invitationPatientId, setInvitationPatientId] = useState('');
  // const [isValidInvitationPatientId, setIsValidInvitationPatientId] = useState(true);
  const [invitationURL, setInvitationURL] = useState('');
  const [copyURLButtonState, setCopyURLButtonState] = useState({
    text: "Copy Meeting URL",
    color: "#556ee6"
  })

  const location = useLocation();

  const lastVitals = vitalsData.length > 0
    ? vitalsData[vitalsData.length - 1]
    : [];

  const interruptionMessageToDisplay = {
    'MEASUREMENT_INTERRUPTED_DISTANCE': 'Patient is not at an optimal distance from the camera. Please ask the patient to move closer to the camera.',
    'MEASUREMENT_INTERRUPTED_DETECTION': "Patient's face is not detected. Please ask the patient to adjust their position or lighting.",
    'MEASUREMENT_POSITION': "Patient's position is not optimal. Please ask the patient to adjust their position."
  }

  const onMeetingStarted = (attendees) => {
    console.log("Attendees:", attendees)
    if (attendees.length > 0) {
      setPatientId(attendees[0].userName)
    } else {
      console.log("Meeting currently empty!")
    }
  }

  // useEffect(() => {
  //   const screenItemsResponse = {
  //     "dashboard": [
  //       {
  //         "type": "VerticalIconValueList",
  //         "key": "recentScanList",
  //         "title": "Recent Scan",
  //         "listItems": [
  //           {
  //             "type": "RecentScan",
  //             "key": "systolic",
  //             "title": "MultiDevice Systolic BP",
  //             "subtitle": "mmHg",
  //             "value": "130"
  //           },
  //           {
  //             "type": "RecentScan",
  //             "key": "diastolic",
  //             "title": "MultiDevice Diastolic BP",
  //             "subtitle": "mmHg",
  //             "value": "83"
  //           },
  //           {
  //             "type": "RecentScan",
  //             "key": "heartRate",
  //             "title": "Heart Rate",
  //             "subtitle": "bpm",
  //             "value": "72"
  //           },
  //           {
  //             "type": "RecentScan",
  //             "key": "heartRateVariability",
  //             "title": "Heart Rate Variability",
  //             "subtitle": "ms",
  //             "value": "90"
  //           },
  //           {
  //             "type": "RecentScan",
  //             "key": "stressIndex",
  //             "title": "Stress Index",
  //             "subtitle": "%",
  //             "value": "80"
  //           }
  //         ]
  //       }
  //     ]
  //   };

  //   const formattedData = screenItemsResponse.dashboard[0].listItems.map(item => ({
  //     key: item.key,
  //     title: item.title,
  //     value: item.value,
  //     unit: item.subtitle,
  //   }));

  //   // setVitalsData(formattedData);
  //   // Append the formatted data to the existing vitals data which is an array of arrays
  //   setVitalsData([...vitalsData, formattedData]);
  // }, [])

  useEffect(() => {
    // Call the function to start the Zoom meeting
    // Extract necessary data for starting the meeting
    async function startZoomMeeting() {
      const { signature, meetingDetails, userDetails, zakToken } = location.state; // or location.query
      const meetingURL = await getDynamicLink(meetingDetails.meetingNumber, meetingDetails.meetingPassword);
      setMeetingDetails({ meetingNumber: meetingDetails.meetingNumber, meetingPassword: meetingDetails.meetingPassword });
      setInvitationURL(meetingURL);
      try {
        console.log("REACT APP CLIENT ID IS ", process.env.REACT_APP_CLIENT_ID)
        zoomUtils.startMeeting(signature, process.env.REACT_APP_CLIENT_ID, meetingDetails.meetingNumber, meetingDetails.meetingPassword, userDetails.displayName, userDetails.email, "", zakToken, meetingURL, onMeetingEnd, setPatientId, onMeetingStarted);
        console.log("GENERATED SIGNATURE IS ", signature)
      } catch (error) {
        console.error('Error starting meeting:', error);
      }
    }

    startZoomMeeting()
  }, [location]);

  useEffect(() => {
    console.log("ID:", patientId)
    console.log("Listening..")
    // Set up WebSocket connection
    if (!patientId)
      return () => { }

    const socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}?userSpecialCode=${patientId}`);

    let prevState = "";

    socket.onmessage = async (event) => {
      const message = JSON.parse(event.data);

      if (message.state === 'MEASUREMENT_IDLE') {
        return;
      }

      const interruptedMesurementState = isMeasurementInterrupted(prevState, message.state);
      // console.log(`previous state: ${prevState}, received state: ${message.state}, interrupted state: ${interruptedMesurementState}`)
      prevState = message.state;
      if (interruptedMesurementState) {
        setMeasurementState(interruptedMesurementState);
      } else {
        console.log("Setting measurement state:", message.state)
        setMeasurementState(message.state);
      }

      // Set the measurement mode
      if (message.mode) {
        setmeasurementMode(message.mode);
      }

      if (message.state === 'MEASUREMENT_UPDATED' || message.state === 'MEASUREMENT_CONTINUING') {
        setProgress(message.progress);
      }

      if ((message.state === 'MEASUREMENT_CONTINUING' || message.state === 'MEASUREMENT_DONE') && message.data && message.measurementId) {
        const screenItemsResponse = await getScreenItems(message.data, message.measurementId);
        if (screenItemsResponse && screenItemsResponse.dashboard[0]) {
          const formattedData = screenItemsResponse.dashboard[0].listItems.map(item => ({
            key: item.key,
            title: item.title,
            value: item.value,
            unit: item.subtitle,
            iconURL: item.icon.dark
          }));
          setVitalsData((prev) => [...prev, formattedData]);
        }
      }
      if (message.state === 'MEASUREMENT_DONE') {
        setProgress(0);
      }
    };

    socket.onopen = () => {
      console.log('WebSocket connected for patient with id:', patientId);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setMeasurementInfo("Failed to connect to patient's vitals channel")
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      console.log("Closing socket")
      socket.close();
    };
  }, [patientId])

  useEffect(() => {
    const handleUnload = () => {
      const data = JSON.stringify({
        meetingId: meetingDetails.meetingNumber.toString(),
        token: 'Bearer ' + localStorage.getItem('zoomAccessToken')
      });
      navigator.sendBeacon(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/meetings/end`, data);
    };

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [meetingDetails]);

  const onMeetingEnd = (error) => {
    // Using this method to entirely refresh the page instead of navigating to it without refreshing. This is due to an issue with the zoom video not showing up.
    window.location.href = '/dashboard';
    // let errorMessage = "An unexpected error happened. Please try starting another meeting."
    // if (error) {
    //   // We get errorCode 200 when we try to reconnect to a meeting that has ended or expired. So we remove the meetingDetails that we used to try to reconnect.
    //   // Which also removes the reconnection button
    //   if (error["errorCode"] === 200) {
    //     errorMessage = "Your meeting ended or expired. Please start another meeting."
    //     // localStorage.removeItem('meetingDetails')
    //   } else {
    //     errorMessage = `Error joining the meeting. ${JSON.stringify(error)}`;
    //   }
    // }
    // alert(errorMessage)
  };

  useEffect(() => {
    // Measurement state and patient id are empty strings
    if (!patientId) {
      setMeasurementInfo("Waiting for the patient...");
      setProgress(0);
      setMeasurementState('')
    }

    if (measurementState === 'MEASUREMENT_STARTED') {
      setMeasurementInfo("Measuring patient vitals...");
      setVitalsData([]);
      setProgress(0);
    }

    if (measurementState === 'MEASUREMENT_INTERRUPTED_DISTANCE' || measurementState === 'MEASUREMENT_INTERRUPTED_DETECTION' || measurementState === 'MEASUREMENT_POSITION') {
      setProgress(0);
      setInterruptionMessage(interruptionMessageToDisplay[measurementState]);
      setMeasurementInfo("Measurement interrupted");
    }

    if (measurementState === 'MEASUREMENT_AWAITING_ACTION') {
      setMeasurementInfo("Waiting for patient to take action...");
    }

    if (measurementState === "MEASUREMENT_DETECTING") {
      setMeasurementInfo("Detecting patient's face...");
    }

    if (measurementState === "MEASUREMENT_DISTANCE") {
      setMeasurementInfo("Detecting patient's distance...");
    }

    if (measurementState === "MEASUREMENT_CALCULATING") {
      setMeasurementInfo("Calculating patient vitals...");
    }

    if (measurementState === "MEASUREMENT_FAILED") {
      setMeasurementInfo("Failed to measure patient vitals");
    }

    if (['MEASUREMENT_STARTED', 'MEASUREMENT_UPDATED'].includes(measurementState)) {
      setMeasurementInfo("Measuring patient vitals...");
    }

    if (measurementState === 'MEASUREMENT_DONE') {
      setMeasurementInfo("Patient vitals");
    }

    if (patientId && (measurementState === "MEASUREMENT_CANCELLED" || measurementState === '')) {
      setProgress(0);
      setMeasurementInfo("Waiting for patient to start measuring");
    }

    if (measurementState !== 'MEASUREMENT_INTERRUPTED_DISTANCE' && measurementState !== 'MEASUREMENT_INTERRUPTED_DETECTION' && measurementState !== 'MEASUREMENT_POSITION') {
      setInterruptionMessage('');
    }

  }, [measurementState, patientId])


  const handleCopyMeetingURLToClipboard = async () => {
    await navigator.clipboard.writeText(invitationURL);
    setCopyURLButtonState({
      text: "Copied to clipboard!",
      color: "green"
    });

    setTimeout(() => {
      setCopyURLButtonState({
        text: "Copy Meeting URL",
        color: "#556ee6"
      });
    }, 2000);
  }

  // const handleInvitationPatienIdChange = (event) => {
  //   setInvitationPatientId(event.target.value);
  //   setIsValidInvitationPatientId(validateInvitationPatientId(event.target.value));
  // }

  // const validateInvitationPatientId = (patientId) => {
  //   // Must be a 6 digit number
  //   return /^\d{6}$/.test(patientId);
  // }

  return (
    <div class="row">
      <div class="column">
        <div id="meetingSDKElement"></div>
      </div>
      <div class="column vitals">
        <div className="flexContainer">
          <div className='vitalsNumbersRow'>
            {interruptionMessage && <p className="interruptionMessage">{interruptionMessage}</p>}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
              <div className='measurementInfoContainer'>
                <p className="measurementInfo">{measurementInfo}</p>
                {['MEASUREMENT_STARTED', 'MEASUREMENT_UPDATED', 'MEASUREMENT_CONTINUING'].includes(measurementState) && measurementMode === "CONTINUOUS" && <div className="vitals-loading-spinner"></div>}
              </div>
            </div>
            {vitalsData.length > 0 &&
              // <VitalsColumn
              //   vitalsData={lastVitals}
              //   hoveredKey={hoveredKey}
              //   setHoveredKey={setHoveredKey} />
              <VitalsChartsGrid
                vitalsData={vitalsData}
              // hoveredKey={hoveredKey}
              // setHoveredKey={setHoveredKey}
              />
            }
            {['MEASUREMENT_STARTED', 'MEASUREMENT_UPDATED', 'MEASUREMENT_CONTINUING'].includes(measurementState) && measurementMode !== "CONTINUOUS" && <MeasurementProgress progress={progress} />}
            {invitationURL && <ActionButton
              iconPath="Video"
              text={copyURLButtonState.text}
              color={copyURLButtonState.color}
              width='35%'
              padding='10px 10px'
              fontSize='15px'
              onClick={handleCopyMeetingURLToClipboard}
            />}
          </div>
          {/* <div className='vitalsChartsRow'>
            <VitalsChartsGrid
              vitalsData={vitalsData}
              hoveredKey={hoveredKey}
              setHoveredKey={setHoveredKey}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MeetingPage;