import React, { useEffect } from 'react';
import './stylesheets/LoginPage.css';
import { isAuthenticated } from '../utils/auth';
import { useNavigate } from "react-router-dom"
import ActionButton from '../components/ActionButton';

const LoginPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard'); // Redirect to the meeting page if authenticated
        }
    }, [navigate]);

    const handleLogin = () => {
        window.location.href = `${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/oauth`;
    };

    return (
        <div>
            <div className="login-container">
                <div className="login-box">
                    <ActionButton
                        iconPath="Video"
                        text="Login with Zoom"
                        onClick={handleLogin}
                    />
                </div>
            </div>
        </div >
    );
};

export default LoginPage;
